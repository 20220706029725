/** 关于我们 */
<template>
  <div class="about-us">
    <!-- 公司简介 -->
    <section class="us-info mt-30 content">
      <div class="ipbs-title">
        <h4>平台介绍</h4>
      </div>
      <el-row :gutter="$isPc?20:0" :class="{'plr-10':!$isPc}">
        <el-col :span="$isPc?15:24">
          <p class="IPBS-info">{{aboutUsText}} </p>
        </el-col>
        <el-col :span="$isPc?9:24" class="activity-item mb-20">
          <el-image :src="require('@/assets/images/about-us-img1.jpg')"></el-image>
        </el-col>
      </el-row>
    </section>
    <!-- 关于我们 -->
    <section class="us-power content">
      <div class="ipbs-title">
        <h4>关于我们</h4>
      </div>
      <el-row :gutter="$isPc?20:0" :class="['us-power-list',{'plr-10':!$isPc}]">
        <el-col :span="$isPc?12:24" class="mb-20" v-for="(item,index) in powers" :key="index">
          <div class="us-power-item">
            <div class="power-img flex-center">
              <el-image :src="item.img" fit="contain"> </el-image>
            </div>
            <h4 class="power-title ptb-20">{{item.title}}</h4>
            <p class="power-info">{{item.text}}</p>
          </div>
        </el-col>
      </el-row>
    </section>
    <!-- 联系我们 -->
    <section class="us-contact content">
      <div class="ipbs-title">
        <h4>联系我们</h4>
      </div>
      <el-row :gutter="$isPc?20:0" :class="['us-contact-list',{'plr-10':!$isPc}]">
        <el-col :span="$isPc?12:24" class="mb-20" v-for="(item,index) in contact" :key="index">
          <div class="us-contact-item">
            <i class="contact-icon" :class="item.icon"></i>
            <h4 class="contact-title ptb-20">{{item.title}}</h4>
            <i v-if="$isPc" class="contact-line" :style="{backgroundColor:item.color}"></i>
            <p class="contact-info flex-center">{{item.text}}</p>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Activity',
  data () {
    return {
      aboutUsText: '北京经济技术开发区知识产权金融创新服务平台是经北京市科委、中关村管委会立项支持的专业平台，是经开区建设首都国际科技创新中心"三城一区"主平台知识产权金融创新工作的重要载体。平台以“亦知贷”为核心服务产品，以线上网站系统、线下运营大厅为依托，聚集首都一流的金融机构、知识产权运营机构，推动建立经开区知识产权质押融资、融资租赁、证券化等创新服务体系，强化知识产权金融服务信息共建共享，全面提升经开区知识产权金融服务水平。',
      powers: [
        { img: require('@/assets/images/yzkc-logo.png'), title: '亦庄科创公司', text: '亦庄科创是北京亦庄投资控股有限公司旗下全资子公司，公司以科技创新为引领，对接政府、三城及大学大院大所，探索科技创新服务模式和成果转化路径，服务全国科技创新中心“三城一区”主平台建设，协同推进升级版开发区打造全球影响力的成果转化承载区、技术创新示范区。' },
        { img: require('@/assets/images/zzzx-logo.jpg'), title: '中知在线', text: '中知在线是国内领先的知识产权金融服务机构，公司拥有国内一流的知识产权金融研究与实践团队，与全国百余家金融机构合作开展合作，探索实践知识产权质押融资产品化、融资租赁、信托、证券化等金融创新模式，为科技型中小企业提供全面、高效、便捷的一站式知识产权金融服务。' },
      ],
      contact: [
        { icon: 'el-icon-location-information', title: '公司地址', text: '北京经济技术开发区荣华中路10号亦城国际中心1层知识产权运营服务大厅' },
        { icon: 'el-icon-phone-outline', title: '联系电话', text: this.$store.getters.configs.hotline_phone },
      ],
      pastActivity: [],
      newActivityLoading: true,
      pastActivityLoading: true,
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.IPBS-info {
  text-align: left;
  &.line-all {
    -webkit-line-clamp: initial;
  }
}

// 关于我们
.us-power {
  .us-power-list {
    .us-power-item {
      min-height: 200px;
      padding: 20px;
      box-shadow: 0px 0px 24px 0px rgba(52, 126, 237, 0.23);
      text-align: center;
      .power-img {
        height: 80px;
        .el-image {
          max-height: 100%;
        }
      }
      .power-info {
        text-align: left;
        line-height: 26px;
      }
    }
  }
}
// 联系我们
.us-contact {
  .us-contact-list {
    .us-contact-item {
      min-height: 100px;
      padding: 20px;
      box-shadow: 0px 0px 24px 0px rgba(52, 126, 237, 0.23);
      text-align: center;
      .contact-icon {
        font-size: 26px;
        color: #347eed;
      }
      .contact-line {
        display: inline-block;
        width: 70px;
        height: 7px;
        margin-bottom: 20px;
        background-color: #347eed;
      }
      .contact-info {
        height: 40px;
        line-height: 20px;
      }
    }
  }
}
@media (min-width: 768px) {
  // 公司简介
  .us-info {
    .ipbs-title h4 {
      text-align: left;
      padding: 20px 0;
    }
  }
}
</style>